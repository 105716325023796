import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';
import UserInfo from "./UserInfo.js";
import { obterAnoCorrente } from './utils/date-utils.js';


export default function () {
  const [prefeitura, setPrefeitura] = useState();

  useEffect(() => {
    async function codigoPortal() {
      let codigo = await UserInfo.getPrefeituraCodigoPortal();
      if (codigo !== undefined) {
        setPrefeitura(codigo);
        localStorage.setItem("codigoPortal", codigo);
      }
    }
    codigoPortal();
  }, []);

  function render() {
    if (prefeitura !== undefined) return <Redirect to={`/${prefeitura}/home`} />;
  }

  return (
    <SideNavBarLayout title={appInfo.title}>
      <Switch>
        {routes.map(({ path, component }) => (
          <Route
            exact
            key={path}
            path={path}
            component={component}
          />
        ))}
        {render()}
      </Switch>
      <Footer>
        Copyright © 2013-{obterAnoCorrente()} Trimap Assessoria Tributária LTDA.
        <br />
        Todos os direitos reservados
      </Footer>
    </SideNavBarLayout>
  );
}

