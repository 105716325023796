import React, { Component } from "react";
import uiInfo from "../../ui-info";
import api from "../../service/api";
import { DataGrid, Column, Paging, Pager } from "devextreme-react/data-grid";
import Form, { SimpleItem, ButtonItem, Label } from "devextreme-react/form";
import UserInfo from "../../UserInfo";
import { getReport } from "../../service/showReport";
import notify from "devextreme/ui/notify";
import { obterAnoCorrente, obterUltimosSeisAnos } from "../../utils/date-utils";

export default class ConsultarEscrituracaoBox extends Component {
  constructor(props) {
    super(props);

    this._gridInstance = React.createRef();
    this.state = { datasource: [], exercicio: obterAnoCorrente().toString() };
    this.consultarEscrituracao = this.consultarEscrituracao.bind(this);
    this.imprimirEscrituracao = this.imprimirEscrituracao.bind(this);
    this.cancelarEscrituracao = this.cancelarEscrituracao.bind(this);

    this.anoOptions = {
      editorType: "dxSelectBox",
      dataSource: obterUltimosSeisAnos().map(ano => ano.toString())
    };
  }

  componentDidMount() {
    const ano = new Date().getFullYear();
    api
      .get(
        `/escrituracao/${UserInfo.getPrefeitura()}/${UserInfo.getCpbsId()}/${ano}`
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ datasource: response.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  consultarEscrituracao() {
    api
      .get(
        `/escrituracao/${UserInfo.getPrefeitura()}/${UserInfo.getCpbsId()}/${this.state.exercicio
        }`
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ datasource: response.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async imprimirEscrituracao({ data }, imprimirDam) {
    if (!imprimirDam || data.dam) {
      try {
        await getReport(
          `/report/cef/${data.id}/${UserInfo.getCpbsId()}/${data.prefeituraId
          }/${imprimirDam}`
        );
      } catch (error) {
        notify(error, "error");
      }
    }
  }

  async imprimirLivroFiscal({ data }) {
    try {
      await getReport(
        `/report/cpbs/livro_fiscal/${UserInfo.getPrefeitura()}/${data.id}`
      );
    } catch (error) {
      notify(error, "error");
    }
  }

  cancelarEscrituracao(data) {
    console.log(data);
  }

  render() {
    return (
      <React.Fragment>
        <div className={"content-block dx-card responsive-paddings"}>
          <h2>Consultar Escriturações</h2>
          <Form
            id="exercicio-form"
            formData={this.state}
            labelLocation="top"
            colCount={10}
          >
            <SimpleItem
              dataField="exercicio"
              editorType={"dxSelectBox"}
              editorOptions={this.anoOptions}
              colSpan={2}
            >
              <Label text="Exercício" />
            </SimpleItem>
            <ButtonItem
              colSpan={3}
              verticalAlignment={"bottom"}
              horizontalAlignment={"left"}
              buttonOptions={{
                text: "Consultar",
                type: "default",
                width: "150px",
                onClick: this.consultarEscrituracao,
              }}
            />
            <ButtonItem
              colSpan={5}
              verticalAlignment={"bottom"}
              horizontalAlignment={"right"}
              buttonOptions={{
                text: "Relatório de escriturações do exercício (IMCOMPLETO)",
                type: "default",
                width: "280px",
                onClick: this.consultarEscrituracao,
              }}
            />
          </Form>
          <br />
          <br />
          <DataGrid
            ref={(grid) => (this._gridInstance = grid)}
            dataSource={this.state.datasource}
            showBorders={true}
            rowAlternationEnabled={true}
            remoteOperations={true}
            columnAutoWidth={true}
            wordWrapEnabled={window.innerWidth < 800}
            filterSyncEnabled={true}
          >
            <Column
              type="buttons"
              buttons={[
                {
                  icon: "doc",
                  hint: "Imprimir CEF",
                  onClick: ({ row }) => this.imprimirEscrituracao(row, false),
                },
                {
                  icon: "docfile",
                  hint: "Imprimir Livro Fiscal",
                  onClick: ({ row }) => this.imprimirLivroFiscal(row),
                },
                {
                  icon: "exportpdf",
                  hint: "Imprimir DAM",
                  onClick: ({ row }) => this.imprimirEscrituracao(row, true),
                },
                {
                  icon: "remove",
                  hint: "Cancelar",
                  onClick: this.cancelarEscrituracao,
                },
              ]}

            />
            <Column
              dataField={"id"}
              allowEditing={false}
              caption={"#"}
              alignment="center"
              width={80}
            />
            <Column dataField={"competencia"} caption={"Competência"} />
            <Column dataField={"modalidadeStr"} caption={"Modalidade"} />
            <Column dataField={"cv"} caption={"CV"} />
            <Column
              dataField={"semMovimento"}
              caption={"Sem Movimento"}
              editorOptions={{ editorType: "dxCheckBox" }}
            />
            <Column
              dataField={"totalServicosPrestados"}
              caption={"Vr. Total (R$)"}
              format={uiInfo.formatoNumerico.n2}
            />
            <Column
              dataField={"totalIss"}
              caption={"Vr. Imposto (R$)"}
              format={uiInfo.formatoNumerico.n2}
            />
            <Paging defaultPageSize={uiInfo.tamanhoPaginaPadrao} />
            <Pager
              showInfo={true}
              showNavigationButtons={true}
              showPageSizeSelector={true}
              allowedPageSizes={uiInfo.tamanhoPaginaOpcoes}
            />
          </DataGrid>
        </div>
      </React.Fragment>
    );
  }
}
