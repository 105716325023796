export const mesOptions = {
  editorType: "dxSelectBox",
  searchEnabled: false,
  placeholder: " ",
  dataSource: [
    { id: 1, descricao: "JANEIRO" },
    { id: 2, descricao: "FEVEREIRO" },
    { id: 3, descricao: "MARÇO" },
    { id: 4, descricao: "ABRIL" },
    { id: 5, descricao: "MAIO" },
    { id: 6, descricao: "JUNHO" },
    { id: 7, descricao: "JULHO" },
    { id: 8, descricao: "AGOSTO" },
    { id: 9, descricao: "SETEMBRO" },
    { id: 10, descricao: "OUTUBRO" },
    { id: 11, descricao: "NOVEMBRO" },
    { id: 12, descricao: "DEZEMBRO" },
  ],
  valueExpr: "id",
  displayExpr: "descricao",
};


export const obterAnoCorrente = () => {
  return new Date().getFullYear();
}

export const obterUltimosSeisAnos = () => {
  let listaAnos = [];
  let currentYear = obterAnoCorrente();
  while (listaAnos.length < 6) {
    listaAnos.push(currentYear);
    currentYear--;
  }
  return listaAnos;
}

export const anoOptions = {
  items: obterUltimosSeisAnos(),
  placeholder: " ",
};
